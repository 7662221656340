import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../../components/layout'

import Header from '../../components/header'
import Columns from '../../components/columns'
import Quote from '../../components/quote'
import Sidebar from '../../components/sidebar'
import FinancialPlanningNav from '../../components/financial-planning-nav'
import Video from '../../components/video'

import videos from '../../lib/videos'

const image = require('../../images/samples/retirement5.png')

const InvestmentServicesPage = () => (
  <Layout>
    <Helmet
      title={'Retirement Planning'}
      meta={[
        {
          name: 'description',
          content:
            // tslint:disable-next-line:max-line-length
            "Have questions about your financial future? Set up your complimentary consultation and let's define a retirement plan that is right for you.",
        },
        {
          name: 'keywords',
          content:
            // tslint:disable-next-line:max-line-length
            'Adage Financial Consultants, David Templeton, Little Rock Arkansas, Financial Advisor, Financial Planner, 401k, Safe Money, Annuity',
        },
      ]}
    />
    <Header headline={'Retirement Planning'} image={image} />
    <Columns background>
      <Sidebar>
        <FinancialPlanningNav />
      </Sidebar>
      <div className="marketing__column">
        <Quote
          // tslint:disable-next-line:max-line-length
          text="How do you define a successful retirement? Planning and thinking ahead can lead to a very active, satisfying life in your retirement years."
        />
        <Video youTubeUrl={videos.pages.retirement.url} />
        <p>
          Take some time to imagine what your future lifestyle would be like,
          and then consider these questions:
        </p>
        <ul className="marketing__checklist">
          <li> Where will you live</li>
          <li> What will you do</li>
          <li> What activities will you participate in</li>
          <li>
            Will you have enough income to maintain or elevate your current
            lifestyle
          </li>
          <li>
            What can you do now to better position yourself for a successful
            retirement
          </li>
          <li>
            Have you met with a professional who does comprehensive financial
            planning
          </li>
          <li>
            Do you have a written retirement plan, put together specifically for
            you by a financial professional
          </li>
        </ul>
        <p>
          Baby boomers are redefining age and retirement. Realizing the
          retirement you want, starts with your own personalized definition.
        </p>
        <p>
          Planning for retirement shouldn’t be difficult or painful. It should
          be a gratifying process knowing that you’ve taken the proper steps to
          achieve the retirement lifestyle that you’ve dreamed of. The earlier
          and more carefully you plan, the more likely you are to realize your
          vision of the future and enjoy yourself when retirement actually
          comes. Adage Financial Consultants can guide you step-by-step through
          the retirement planning process and help you create a plan that meets
          your unique goals and objectives.
        </p>
      </div>
    </Columns>
  </Layout>
)

export default InvestmentServicesPage
