const videos = {
  pages: {
    welcome: {
      title: 'Welcome to our website',
      url: 'https://youtube.com/embed/8x0WLeBl3Lg',
    },
    retirement: {
      title: 'The importance of a written retirement plan',
      url: 'https://youtube.com/embed/Aq_sSQtiYpg',
    },
    investmentServices: {
      title: "We'd like to help you plan your retirement",
      url: 'https://youtube.com/embed/I5q2kEcpKnM',
    },
  },
  browse: [
    {
      title: "Where should you invest if you're afraid of risk?",
      url: 'https://youtube.com/embed/DKmjWkt4ryU',
      tags: ['Safe Money'],
    },
    {
      title: 'What to do with your 401K',
      url: 'https://youtube.com/embed/2LOUlqeiFN8',
      tags: ['401k'],
    },
    {
      // Annuity News
      title: 'What the Fiscal Cliff Means to You',
      url: 'https://youtube.com/embed/QKu_QctR6_0',
      tags: ['Safe Money'],
    },
    {
      title: 'US Debt Downgrade',
      url: 'https://youtube.com/embed/-tYRDYWk7Nc',
      tags: ['Financial Planning'],
    },
    {
      title: 'Retirement Inflation Protection',
      url: 'https://youtube.com/embed/vsWvfyfibC0',
      tags: ['Retirement'],
    },
    {
      title: 'Is My Pension Safe?',
      url: 'https://youtube.com/embed/CW8kf-_ESEk',
      tags: ['Retirement'],
    },
    {
      title: 'Investors Afraid',
      url: 'https://youtube.com/embed/uB2k8izWe-A',
      tags: ['Financial Planning'],
    },
    {
      title: 'Investors afraid of stocks retirement annuity',
      url: 'https://youtube.com/embed/E136b5JujFA',
      tags: ['Retirement', 'Financial Planning'],
    },
    {
      title: 'How To Maximize Social Security Benefits',
      url: 'https://youtube.com/embed/lWq1JjDOQpA',
      tags: ['Social Security', 'Retirement'],
    },
    {
      title: 'How Financial Advisors Are Paid',
      url: 'https://youtube.com/embed/KbyWqGQnsX0',
      tags: ['Retirement', 'Financial Planning'],
    },
    {
      // Annuity News
      title: 'Are We In For A Stock Market Correction?',
      url: 'https://youtube.com/embed/hXyLt0GjNU4',
      tags: ['Financial Planning'],
    },
    {
      // Annuity News
      title: "Are you paying taxes on money you aren't spending?",
      url: 'https://youtube.com/embed/BG5CcguXz2Q',
      tags: ['Financial Planning'],
    },
    {
      // Annuity News
      title: 'Baby Boomers Need Financial Advice',
      url: 'https://youtube.com/embed/Cjs5kfP1OY0',
      tags: ['Retirement', 'Financial Planning'],
    },
  ],
}

export default videos
