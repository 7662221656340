import React from 'react'

interface IVideoProps {
  youTubeUrl: string
  title?: string
}

const Video = (props: IVideoProps) => (
  <section className="video">
    <div className="video__container">
      <iframe
        title="video"
        width="560"
        height="349"
        src={props.youTubeUrl}
        frameBorder="0"
        allow="autoplay; encrypted-media"
        allowFullScreen
      />
    </div>
    {!!props.title && <h2>{props.title}</h2>}
  </section>
)

export default Video
